import { IVP_BASE_URL } from "../config/config";
const ProceedToCtf = () => {

    const handleLoginRedirect = () => {
        window.open(IVP_BASE_URL + '/loginRedirect?client_id=ctf', '_blank');

    };

    return (
        <div>
            <div className="wrapper-live-quiz-user">

                <div className="container d-flex justify-content-center">
                    <button className="start-button btn btn-warning px-5 fw-bold" onClick={handleLoginRedirect}>
                        Proceed  to CTF
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProceedToCtf