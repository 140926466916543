//export  const API_BASE_URL = 'http://10.244.3.75:9095/quiz-ctf';  
//export  const API_BASE_URL = 'https://ctf-ivp.hyderabad.cdac.in/quiz-ctf';
export const API_BASE_URL = 'https://ctf.isea.app/quiz-ctf';
// export const API_BASE_URL = 'https://ctf.isea.in/quiz-ctf';


// ------------------------------------IVP URL--------------------------------------

//export const IVP_BASE_URL ='https://ivp.iseapmu.in/backend';
//export const IVP_BASE_URL ='https://ivp-staging.hyderabad.cdac.in/backend';
//export const IVP_BASE_URL ='http://dhananjayk.hyderabad.cdac.in/backend';
export const IVP_BASE_URL ='https://ivp.isea.app/backend';
// export const IVP_BASE_URL ='https://ivp.isea.in/backend';

// -------------------------------------IVP REDIRECT URL---------------------------------------------------------

// export const  IVP_REDIRECT_URL ='https://ivp-staging.hyderabad.cdac.in/backend/loginRedirect?client_id=ivp&source=ctf';
// export const IVP_REDIRECT_URL ='http://dhananjayk.hyderabad.cdac.in/backend/loginRedirect?client_id=ivp&source=ctf';
//export const IVP_REDIRECT_URL ='http://dhananjayk.hyderabad.cdac.in/backend/loginRedirect?client_id=ctf&source=ctf';
export const  IVP_REDIRECT_URL ='https://ivp.isea.app/backend/loginRedirect?client_id=ctf&source=ctf';
//export const IVP_REDIRECT_URL = 'https://ivp.isea.in/backend/loginRedirect?client_id=ctf&source=ctf'
 







