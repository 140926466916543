import { Link, useParams, useNavigate } from 'react-router-dom';
import Services from '../../src/Service/Service';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import swal from "sweetalert"
import Nav from 'react-bootstrap/Nav';
import { CSVLink } from 'react-csv';
import UserUtil from '../UserUtil/UserUtil';

const UserScoreBoard = () => {
    let navigate = useNavigate();
    const [chartData, setChartData] = useState([]);
    const [selectedCtfName, setSelectedCtfName] = useState("");
    const [tableData, setTableData] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [chartPerformanceOptions, setChartPerformanceOptions] = useState({});
    const [challengeListData, setChallengeListData] = useState(null);
    const [activeTab, setActiveTab] = useState('ctfGraph');
    const [selectedCtf, setSelectedCtf] = useState('');
    const [graphOptions, setGraphOptions] = useState({});

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };

    console.log("active tab",activeTab);


    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',

                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius: '5px'
            },
        },
    };


    const columns = [
        {
            name: 'CTF Name',
            selector: (row) => row.CTFName,
            center: true
        },
        {
            name: 'Score',
            selector: (row) => row.score,
            center: true,

        },

    ];


    const column = [
        {
            name: 'Challenge Name',
            selector: (row) => row.challengeName,
            center: true
        },
        {
            name: 'Correct',
            selector: (row) => row.correct,
            center: true,

        },
        {
            name: 'Wrong',
            selector: (row) => row.wrong,
            center: true,

        },
        {
            name: 'Attempts',
            selector: (row) => row.attempts,
            center: true,

        },

    ];


    useEffect(() => {

        Services.GetEmailsListinChallengeList()
            .then(response => {
                setChallengeListData(response.data);
                console.log("data", response.data);
                if (response.data && response.data.length > 0) {
                    const firstCTF = response.data[0];
                    setSelectedCtf(firstCTF.challengeListId);
                    fetchGraphData(firstCTF.challengeListId, firstCTF.challengeListName); // Fetch graph data for the first CTFfirst CTF

                }
            }).catch(err => {

                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
            })

    }


        , []);




    useEffect(() => {
        if (activeTab === 'ctfGraph') {
            Services.GetScoreboardofUserofCTF().then(response => {
                setChartData(response.data);
                console.log("data", response.data);
                const apiData = response.data;
                const transformedChartData = Object.values(apiData).map(item => ({
                    name: item.CTFName,
                    data: [item.score]
                }));


                const options = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'CTF'
                    },
                    xAxis: {
                        categories: transformedChartData.map(item => item.name), // Assuming transformedData has the required format
                        crosshair: true,
                        accessibility: {
                            description: 'CTF'
                        },
                        title: {
                            text: 'CTF'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Scores'
                        }
                    },
                    // tooltip: {
                    //     valueSuffix: ' (1000 MT)'
                    // },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                            color: '#FFA500'
                        }
                    },
                    series: [{
                        name: 'Score', // Series name
                        data: transformedChartData.map(item => item.data[0]) // Assuming data is in the format: { name: 'Username', data: [score] }
                    }],
                    exporting: {
                        enabled: true,
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'downloadCSV'
                                ]
                            }
                        }
                    }
                };


                setChartOptions(options);


            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
                // else {
                //     swal("Message!", "Server error occurred", "warning");
                // }
            });
        }
    }, [])



    const fetchGraphData = (challengeListId, challengeListName) => {
        setSelectedCtfName(challengeListName);
        Services.GetScoreboardofUserofCTFPerformance(challengeListId)
            .then(response => {
                setGraphOptions(response.data);
                const apiData = response.data; // Assuming the API response is an array of 
                console.log("Challenge List Name:", apiData)

                const formattedTableData = Object.keys(apiData).map(challengeName => ({
                    challengeName: challengeName,
                    correct: apiData[challengeName].correct,
                    wrong: apiData[challengeName].wrong,
                    attempts: apiData[challengeName].attempts,
                }));

                setTableData(formattedTableData);


                const transformedChartData = Object.keys(apiData).map(challengeName => ({
                    name: challengeName,
                    data: [
                        apiData[challengeName].correct,
                        apiData[challengeName].wrong,
                        apiData[challengeName].attempts
                    ]
                }));

                const categories = transformedChartData.map(item => item.name);

                const options = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: challengeListName
                    },
                    xAxis: {
                        categories: categories, // Using challenge names as categories
                        crosshair: true,
                        accessibility: {
                            description: 'Challenges'
                        },
                        title: {
                            text: 'Challenges'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Count'
                        }
                    },
                    tooltip: {
                        shared: true
                    },
                    series: [{
                        name: 'Correct',
                        data: transformedChartData.map(item => item.data[0]), // Correct count
                        color: Highcharts.getOptions().colors[0]
                    }, {
                        name: 'Wrong',
                        data: transformedChartData.map(item => item.data[1]),
                        color: Highcharts.getOptions().colors[1]
                    }, {
                        name: 'Attempts',
                        data: transformedChartData.map(item => item.data[2]),
                        color: Highcharts.getOptions().colors[2]
                    }],
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0

                        }
                    },
                    exporting: {
                        enabled: true,
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'downloadCSV'
                                ]
                            }
                        }
                    }
                };

                setChartPerformanceOptions(options);



            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;
                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
            });
    };

    const leaderboardArray = Object.values(chartData);
    const hasLeaderboardData = leaderboardArray.length > 0;


    //    const leaderboardArrayofPerformance = Object.values(graphOptions);
    // const hasLeaderboardDataPerformance = leaderboardArrayofPerformance.length > 0; 

    const downloadCSV = () => {
        if (!chartData.msg) {
            const csvData = leaderboardArray.map((item, index) => ({
                'Sr No': index + 1,
                'CTF Name': item.CTFName,
                'Score': item.score,

            }));

            return (
                <CSVLink
                    data={csvData}
                    filename={'Ctf-list.csv'}
                    className="btn-theme-download"
                >
                    <i className="fas fa-download p-1"></i>Download CSV
                </CSVLink>
            );

        } else {
            return null;
        }
    };


    // console.log("per",leaderboardArrayofPerformance)
    // console.log("per",graphOptions)


    const downloadPerformanceCSV = () => {
        if (!graphOptions.msg) {
            const csvData = tableData.map((item, index) => ({
                'Sr No': index + 1,
                'Challenge Name': item.challengeName,
                'Correct': item.correct,
                'Wrong': item.wrong,
                'Attempts': item.attempts

            }));

            return (
                <CSVLink
                    data={csvData}
                    filename={`${selectedCtfName}-performance-statistics.csv`}
                    className="btn-theme-download"
                >
                    <i className="fas fa-download p-1"></i>Download CSV
                </CSVLink>
            );

        } else {
            return null;
        }
    };

    const renderTabContent = () => {
        if (activeTab === 'ctfGraph') {
            if (!chartData.msg) {
                return (
                    <div className='container custom-tab-content'>
                        <div className='card p-2 my-3'>
                            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </div>
                        <div className="text-end p-2 mt-4">
                            {downloadCSV()}
                        </div>
                        <div className='mt-4'>
                            {hasLeaderboardData !== null && chartData.status !== null ? (
                                <DataTable
                                    columns={columns}
                                    data={leaderboardArray.length > 0 ? leaderboardArray : ''}
                                    highlightOnHover
                                    pointerOnHover
                                    responsive
                                    customStyles={customStyles}
                                    pagination
                                    paginationPerPage={10}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className='container custom-tab-content'>
                        <div className='alert alert-info fw-bold text-center'>
                            No Data available
                        </div>
                    </div>
                );
            }
        } else if (activeTab === 'performanceGraph') {
            if (challengeListData.length === 0) {
                // If challengeListData is not available, render a message or nothing
                return (
                    <div className="container custom-tab-content mt-3">
                        <div className="alert alert-info fw-bold text-center">
                            No CTF data available to select.
                        </div>
                    </div>
                );
            } else {
                // Render content for 'performanceGraph' tab
                return (
                    <div className="container custom-tab-content mt-3">
                        <div className="col-md-4 custom-select-quiz my-4">
                            <h3>Choose CTF</h3>
                            <select
                                className="form-select"
                                data-title="selectQuiz"
                                name="selectQuiz"
                                onChange={(e) => {
                                    const selectedId = e.target.value;
                                    const selectedName = challengeListData.find(challenge => challenge.challengeListId === selectedId)?.challengeListName;
                                    setSelectedCtf(selectedId);
                                    fetchGraphData(selectedId, selectedName);
                                }}
                                value={selectedCtf}
                            >
                                {challengeListData && challengeListData.length > 0 ? (
                                    challengeListData.map(challenge => (
                                        <option key={challenge.challengeListId} value={challenge.challengeListId}>
                                            {challenge.challengeListName}
                                        </option>
                                    ))
                                ) : (
                                    ""
                                )}
                            </select>
                        </div>
    
                        {!graphOptions.msg ? (
                            <div className='card p-2 my-3'>
                                <HighchartsReact highcharts={Highcharts} options={chartPerformanceOptions} />
                            </div>
                        ) : (
                            <div className='alert alert-info fw-bold text-center'>
                                {graphOptions.msg}
                            </div>
                        )}
                        
                        <div className="text-end p-2 mt-4">
                            {downloadPerformanceCSV()}
                        </div>
    
                        <div className='mt-4'>
                            {tableData !== null && tableData.status !== null ? (
                                <DataTable
                                    columns={column}
                                    data={tableData.length > 0 ? tableData : ''}
                                    highlightOnHover
                                    pointerOnHover
                                    responsive
                                    customStyles={customStyles}
                                    pagination
                                    paginationPerPage={10}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                );
            }
        }
    };
    
// console.log("challenge list",challengeListData);



    if (typeof Highcharts === 'object') {
        HighchartsExporting(Highcharts);
    }

    return (
        <div>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/user-dashboard/ctf">
                                User Dashboard
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/dashboard/ctf">
                                CTF
                            </Link>
                        </li> */}

                        <li aria-current="page">Scoreboard</li>

                    </ol>
                    <h2>Scoreboard</h2>
                </div>
            </section>
            <div className='container d-flex justify-content-center mt-4'>
                <Nav variant="pills" activeKey={activeTab} onSelect={handleTabChange} className="custom-nav">
                    <Nav.Item>
                        <Nav.Link eventKey="ctfGraph" className="nav-link-custom">CTF Graph</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="performanceGraph" className="nav-link-custom">Performance Graph</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className='mt-4'>
                {renderTabContent()}
            </div>
        </div>
    )
}

export default UserScoreBoard