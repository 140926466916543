import { Routes, Route, Outlet,Navigate,useLocation} from "react-router-dom";
import { useState, useEffect } from 'react';
import Dashboard from "../Dashboard/DashBoard";
import NavBar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import NewForm from "../NewForm/NewForm";
import Login from "../Login/Login";
import Home from "../Home/Home";
import jwtDecode from 'jwt-decode';
import HomeRouter from "../HomeRouter/HomeRouter";
import Header from "../Header/Header";
import CreateChallenge from "../CreateChallenge.js/CreateChallenge";
import UpdateStandardChallenge from "../UpdateStandardChallenge/UpdateStandardChallenge";
import ViewChallenges from "../ViewChallenges/ViewChallenges";
import CreateChallengeList from "../CreateChallengeList/CreateChallengeList";
import ViewChallengeList from "../ViewChallengeList/ViewChallengeList";
import AssignUsertoChallengeList from "../AssignUsertoChallengeList/AssignUsertoChallengeList";
import CtfDashboard from "../CtfDashboard/CtfDashboard";
import AdminLeaderboard from "../AdminLeaderboard/AdminLeaderboard";
import AdminVideoTutorial from "../VideoTutorial/AdminVideoTutorial";
import UserUtil from "../UserUtil/UserUtil";
const DashBoardRouter = () => {
    const location = useLocation();    
    const currentPathname = location.pathname;
    console.log("path",currentPathname);
    const [isLoading, setIsLoading] = useState(true);
    // const LoggedIn= sessionStorage.getItem("LoggedIn");
    const LoggedIn = UserUtil.getSessionStorage("IsLoggedIn");
    const tok =UserUtil.getSessionStorage("_txyz");
    let roles="";
    let isAuthenticated="";
    let isAdmin="";
    if (LoggedIn === "true" && tok !== 'undefined'){
    // isAuthenticated=sessionStorage.getItem("token");
    isAuthenticated = UserUtil.getSessionStorage("_txyz");
    roles = getRolesFromToken(isAuthenticated);
                            
    function getRolesFromToken(token) {
        // Decode the JWT token
          const decodedToken = jwtDecode(token);

         // Extract the username from the decoded token
         const roles = decodedToken ? decodedToken.roles : null;

         return roles;
     }
  

     isAdmin = roles === "[ROLE_ADMIN]";
    }

    const isQuizSection = currentPathname.startsWith("/dashboard/quiz");
    const isCTFSection = currentPathname.startsWith("/admin-dashboard/ctf");

    return isAuthenticated  && isAdmin ? (
        <div>
          <Header currentSection={isQuizSection ? "quiz" : isCTFSection ? "ctf" : ""} />
            <Routes>
                     {/* Quiz Routing */}
                   {/* <Route path="/" element={<Dashboard />}/> */}
                   {/* <Route path="/quiz" element={<QuizDashboard />}/>
                   <Route path="/quiz/certificate" element={<Certificate/>} />
                   <Route path="/quiz/create-quiz" element={<CreateQuiz/>}/>  
                   <Route path="/quiz/update-quiz/:qId" element={<CreateQuiz/>}/> 
                   <Route path="/quiz/assign-user/:qId"  element={<AssignQuiz />} />   
                   <Route path="/quiz/total-quizzes" element={<TotalQuizzes/>}/>  
                   <Route path="/quiz/quiz-statistics" element={<QuizStatistics/>}/>                   
                   <Route path="/quiz/create-questions" element={<CreateQuestions />}/>
                   <Route path="/quiz/single-choice" element={<SingleChoice />} />
                   <Route path="/quiz/update-single-choice/:qId" element={<SingleChoice/>} />
                   <Route path="/quiz/bulk-upload" element={<BulkUpload />} />
                   <Route path="/quiz/fill" element={<Fill />} />
                   <Route path="/quiz/update-fill/:qId" element={<Fill/>} />
                   <Route path="/quiz/true-false" element={<TrueorFalse/>} />
                   <Route path="/quiz/update-true-false/:qId" element={<TrueorFalse/>} />
                   <Route path="/quiz/total-questions" element={<TotalQuestions />} />
                   <Route path="/quiz/quiz-questions" element={<QuizQuestion />} />                   
                   <Route path="/quiz/new-form"  element={<NewForm />} />   */}

                           {/* CTFD Routing */}
                   <Route path="/ctf" element={<CtfDashboard/>} />
                   <Route path="/ctf/create-challenge" element={<CreateChallenge/>} />
                   <Route path="/ctf/update-standard-challenge/:challengeId" element={<UpdateStandardChallenge/>} />
                   <Route path="/ctf/view-challenges" element={<ViewChallenges/>}/>
                   <Route path="/ctf/create-ctf-list" element={<CreateChallengeList />} />
                   <Route path="/ctf/update-ctf-list/:challengeListId" element={<CreateChallengeList/>} />
                   <Route path="/ctf/view-ctf-list" element={<ViewChallengeList/>} />
                   <Route path="/ctf/assign-user-ctf-list/:challengeListId" element={<AssignUsertoChallengeList/>} />
                   <Route path="/ctf/leaderboard" element={<AdminLeaderboard/>} />
                   <Route path="/ctf/admin-video-tutorial" element={<AdminVideoTutorial/>} />
            </Routes>
          {/* <Footer /> */}
        </div>
    ): (
        // <Navigate to="/error"/>
        <Navigate to="/" />
    );
}


export default DashBoardRouter;