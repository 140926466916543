import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import Services from '../Service/Service';

class UserUtil {

setSessionStorage(key,value){
  //sessionStorage.setItem(key,value);
  Cookies.set(key, value);
}


setCookies(key, value, options) {
  Cookies.set(key, value, options);
}

getSessionStorage(key){
    //return  sessionStorage.getItem(key);
    return Cookies.get(key);
}

removeSessionStorage(key){
    //return  sessionStorage.removeItem(key);
    Cookies.remove(key);
}


 getUsernameFromToken(val) {
    
    const decodedToken = jwtDecode(val);

    const username = decodedToken ? decodedToken.username : null;

    return username;
  }


   getRolesFromToken(val) {
    // Decode the JWT token
    const decodedToken = jwtDecode(val);

    // Extract the roles from the decoded token
    const roles = decodedToken ? decodedToken.roles : null;

    return roles;
}


// logout(){
//   const allCookies = Cookies.get();
//   Services.Logout()
//   .then(response => {
//       if (response.status === 200) {
//           // sessionStorage.removeItem("token");
//           // sessionStorage.removeItem("LoggedIn");
//           // Cookies.remove("_txyz");
//           // Cookies.remove("IsLoggedIn");
//           // Cookies.remove("referrer");
//           // Cookies.remove("mode");
//           // Cookies.remove("flag");
//           //window.location.href = "/";
//           // navigate("/");
//           //alert("hi");
//           let flag = Cookies.get("__ivpflag");
//           let user_id = Cookies.get("__xyzabc");
//           let body = { user_id };
//           if (flag) {
//               Services.IVPLogout(body)
//                   .then(response => {
//                       if (response.data.status === 1) {
//                           //navigate("/");
//                         //   Cookies.remove("token");
//                         //   Cookies.remove("LoggedIn");
//                         //   Cookies.remove("referrer");
//                         //   Cookies.remove("mode");
//                           // Cookies.remove("__xyzabc");
//                           // Cookies.remove("__ivpflag");
//                           Object.keys(allCookies).forEach(cookieName => {
//                             Cookies.remove(cookieName);
//                           });
//                           // window.location.replace("/");
//                           window.location.href = "/";
//                           //alert("hi");


//                       }
//                   }).catch(err => {

//                   })
//           }
//       }
//   }).catch(err => {

//   })
// }


logout = async () => {
  const allCookies = Cookies.get();
  let flag = Cookies.get("__ivpflag");
  let user_id = Cookies.get("__xyzabc");
  let body = { user_id };

  try {
      // Call the first logout API
      const logoutResponse = await Services.Logout();
      if (logoutResponse.status === 200) {
          // If the flag exists, call the second API
          if (flag) {
              const ivpLogoutResponse = await Services.IVPLogout(body);
              if (ivpLogoutResponse.data.status === 1) {
                  // Remove all cookies after successful logout
                  Object.keys(allCookies).forEach(cookieName => {
                      Cookies.remove(cookieName);
                  });
              }
          }
      }
  } catch (err) {
      console.error('Error during logout process:', err);
  } finally {
          // Clear browser cache to prevent navigating back
          window.history.pushState(null, null, window.location.href);
          window.onpopstate = () => {
              window.history.go(1);
          };
  
          // Replace the current URL to prevent back navigation
          window.location.replace("/");
  }
};






}
 export default new UserUtil()