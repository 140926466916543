// SideMenu.js
import React from 'react';

const SideMenu = ({ selectedMenu, setSelectedMenu }) => {
  return (
    <div id="sidebar-wrapper">
      <ul className="sidebar-nav">
        <li className="sidebar-brand">Menu</li>
        <li className={selectedMenu === 'Dashboard' ? 'active' : ''}>
          <a href="#" onClick={() => setSelectedMenu('Dashboard')} className="m-3">
            <i className="fa fa-chart-bar"></i> Assigned CTF
          </a>
        </li>
        {/* <li className={selectedMenu === 'AssignedQuizzes' ? 'active' : ''}>
          <a href="#" onClick={() => setSelectedMenu('AssignedQuizzes')} className="m-3">
            <i className="fa fa-list-ul"></i> Assigned Quizzes
          </a>
        </li> */}
        <li className={selectedMenu === 'AttemptedChallenges' ? 'active' : ''}>
          <a href="#" onClick={() => setSelectedMenu('AttemptedChallenges')} className="m-3">
            <i className="fa fa-history"></i> Attempted Challenges
          </a>
        </li>
        {/* <li className={selectedMenu === 'QuizStatistics' ? 'active' : ''}>
          <a href="#" onClick={() => setSelectedMenu('QuizStatistics')} className="m-3">
          <i class="ri-bar-chart-fill"></i> Quiz Statistics
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default SideMenu;
