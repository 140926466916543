//import { useEffect } from 'react';
//import '../assets/Style.css';
const UnAuthorized = () => {

    return (
        <div>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>
                            4<span>0</span>4
                        </h1>
                    </div>
                    <p>
                        Page Not Found
                    </p>
                    <a href="/" className='pt-2'>home page</a>
                </div>
            </div>

        </div>
    )
}

export default UnAuthorized;