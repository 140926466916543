import { useState, useEffect } from 'react';
import Services from '../../src/Service/Service';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from "sweetalert"
import Spinner from '../Spinner/Spinner';
import UserUtil from '../UserUtil/UserUtil';

const UserCtfStatistics = ({ selectedMenu, setSelectedMenu }) => {
    let navigate = useNavigate();
    const [selectedChallengeList, setSelectedChallengeList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [challengeLists, setChallengeLists] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const challengesPerPage = 8; // Adjust this number based on how many challenges you want to display per page
    const pageCount = Math.ceil(
        challengeLists ? challengeLists.length / challengesPerPage : 0
    );

    const handleCtfAssigned = (challengeListId) =>{
        navigate("/user-dashboard/ctf-challenges",{ state: { challengeListId: challengeListId } })
    }


    const displayChallenges = challengeLists
        ? challengeLists
            .slice(pageNumber * challengesPerPage, (pageNumber + 1) * challengesPerPage)
            .map((challengeList, index) => (

                <div className="col-md-3" key={challengeList.challengeListId}>
                    {/* <Link to={`/user-dashboard/ctf-challenges/${challengeList.challengeListId}`} > */}
                        <div className="card1" onClick={() => handleCtfAssigned(challengeList.challengeListId)}>
                            <h3 className='text-capitalize'>{challengeList.challengeListName}</h3>
                            {/* <p className='text-capitalize text-primary  mt-4 fw-bold text-center'>{challengeList.courseIdAndName[Object.keys(challengeList.courseIdAndName)[0]]}</p> */}
                            <p className="small">
                                <div className="quiz-info d-flex fw-bold text-black text-center p-2 mt-2">
                                    <div className="questions border-secondary border-opacity-10 border-end w-50">
                                        {challengeList.startDate !== 'NA' ? (
                                            <p className="w-100 mb-0 text-crimson">{challengeList.startDate}</p>
                                        ) : (
                                            <p className="w-100 mb-0 text-crimson">-</p>
                                        )}
                                        <small className="text-success">Start Date</small>
                                    </div>
                                    <div className="timer w-50">
                                        {challengeList.endDate !== 'NA' ? (
                                            <p className="w-100 mb-0  text-crimson">{challengeList.endDate}</p>
                                        ) : (
                                            <p className="w-100 mb-0 text-crimson">-</p>
                                        )}
                                        <small className="text-success">End Date</small>
                                    </div>
                                </div>
                            </p>
                            <div className="go-corner" href="#">
                                <div className="go-arrow">→</div>
                            </div>
                        </div>
                    {/* </Link> */}
                </div>

            ))
        :""
        // <div className="col-md-12  alert  alert-info mt-4 text-center">
        //     <h6 className='fw-bold'>No CTF available.</h6>
        // </div>


    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    function getCardColor(index) {
        const colors = ['#8470FF', '#4682B4', '#2db6fa'];
        return colors[index % colors.length];
    }



    const handleCardClick = () => {
        setSelectedMenu('Dashboard');
        // setShowUserCtfStatistics(true);
    };

    useEffect(() => {

        Services.GetEmailsListinChallengeList()
            .then(response => {
                setTimeout(() => {
                setChallengeLists(response.data);
                // console.log("Dataa", response.data);
                setIsLoading(false);
            }, 2000);
            }).catch(err => {
                setIsLoading(false);
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
            })

    }


        , []);

    const challengesByCategory = {};


    if (selectedChallengeList) {

        // Create a set to keep track of displayed challenge names in each category
        const displayedChallengeNames = {};

        selectedChallengeList.challenges.forEach(challenge => {
            const { category, challengeName } = challenge;
            if (!challengesByCategory[category]) {
                challengesByCategory[category] = [];
            }

            // Check if the challenge name has not been displayed in this category
            if (!displayedChallengeNames[category]?.has(challengeName)) {
                challengesByCategory[category].push(challenge);

                // Add the challenge name to the set to mark it as displayed
                if (!displayedChallengeNames[category]) {
                    displayedChallengeNames[category] = new Set();
                }
                displayedChallengeNames[category].add(challengeName);
            }
        });

        // Now you can work with challengesByCategory based on the selectedChallengeList
    }

    return (
        <div>
             {isLoading ? ( // Conditionally render the spinner while loading
                <div className="spinner-container">
                    <Spinner />
                </div>
            ) : (
                <>
            <div className="col-lg-12 container">
                <header className="section-header">
                    <p className='fs-4'>Assigned CTF</p>
                </header>
                <div className='row'>
                    {displayChallenges}
                </div>



                {challengeLists && challengeLists.length > 0 ? (
                    <ReactPaginate
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={'pagination justify-content-center'}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        previousLinkClassName={'btn  btn-sm btn-outline-primary m-2'}
                        nextLinkClassName={'btn btn-sm btn-outline-primary m-2'}
                        disabledClassName={'page-item disabled'}
                        activeClassName={'btn btn-sm btn-primary m-2 active'}
                        breakLabel={'...'}
                        pageClassName={'btn btn-sm btn-outline-primary m-2'} // Style for each page number container
                        onPageActive={(index) => {
                            // Provide custom class for active/inactive page numbers
                            return index === pageNumber ? 'btn btn-sm btn-primary active' : 'btn btn-sm btn-outline-primary m-2';
                        }}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                    />
                ) :
                    <div className="col-md-12  alert  alert-info mt-4 text-center">
                        <h6 className='fw-bold'>No Assigned CTF available.</h6>
                    </div>
                }

            </div>
            </>
            )}
        </div>
    )
}

export default UserCtfStatistics