import { Link, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import * as XLSX from 'xlsx';
import Services from '../../src/Service/Service'
import { Multiselect } from "multiselect-react-dropdown";
import Sample from "../File/sample1.xlsx"
import '../assets/Style.css'
import '../assets/css/style.css'
import DataTable from 'react-data-table-component';
import swal from "sweetalert"
import $ from 'jquery';
import UserUtil from '../UserUtil/UserUtil';

const AssignUsertoChallengeList = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [excelData, setExcelData] = useState(null);
    const [attended, setAttended] = useState([]);
    const [error, setError] = useState(null);
    const [excel, setExcel] = useState(false);
    const [isListData, setListData] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [errorFileMessage, setErrorFileMessage] = useState(false);
    const [emailOptions, setEmailOptions] = useState([]);
    const [emailChips, setEmailChips] = useState([]);
    const [errorCheckMessage, setErrorCheckMessage] = useState(false);
    const [selectedContent, setSelectedContent] = useState('selectEmails');
    const [isFormValid, setIsFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = React.createRef();
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('');
    const { challengeListId } = useParams();
    let navigate = useNavigate();
    const SampleHeaderValues = ["Emails"];
    const [selectedOptions, setSelectedOptions] = useState([]);

    const onSelect = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setSelectedOptions(selectedList);
    };

    useEffect(() => {
        // Check if there are no selected options
        setIsFormValid(selectedOptions.length > 0);
    }, [selectedOptions]);

    const columnsLinkUsers = [
        { name: 'Sr No', selector: (row, index) => index + 1, sortable: true, center: true, wrap: true, compact: true, maxWidth: '200px' },
        {
            name: 'Name',
            selector: (row) => `${row.first_name} ${row.last_name}`,
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            maxWidth: '1000px'
        },
        { name: 'Email', selector: (row) => row.email, sortable: true, center: true },
        { name: 'Status', selector: (row) => row.user_status, sortable: true, center: true },
    ];

    const handleSelectChange = (event) => {
        const selectedEventValue = event.target.value;
        setSelectedEvent(event.target.value);
        Services.IVPGetEventLinkUserList(selectedEventValue)
            .then(response => {
                console.log("linking emails", response.data.data);
                if (response.data && Array.isArray(response.data.data)) {
                    console.log("Linking emails:", response.data.data);

                    const filteredParticipants = response.data.data.filter(attended => {

                        return attended.user_status === 'attended';
                    });

                    console.log("Filtered Participants:", response.data.data.length);
                    setAttended(filteredParticipants);
                    if (filteredParticipants.length > 0) {
                        setListData(true);
                    } else {
                        setListData(false);
                    }


                } else {
                    console.error("Unexpected response structure:", response);
                }
            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");


                    UserUtil.logout();
                }
            });


    };




    const customStyles = {

        headCells: {
            style: {
                fontSize: '14px',

                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius: '5px'
            },
        },
    };


    const onSubmitEmailsHandler = () => {
        const emailArray = selectedOptions.map(option => option.value);
        const data = { challengeListId: challengeListId, emailList: emailArray }

        const jsonData = JSON.stringify(data);
        console.log("Emails", isFormValid);
        if (isFormValid) {
            Services.UpdateEmailsforChallengeList(jsonData)
                .then(async response => {
                    if (response.data.status === true) {
                        await swal("Success", response.data.msg, "success");
                        navigate("/admin-dashboard/ctf/view-ctf-list");
                        // setSelectedOptions([]);

                    }
                    else {
                        await swal("Message", response.data.msg, "info");
                    }
                }).catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        UserUtil.logout()
                    }
                    else if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        swal("Message!", errorMessages, "warning");
                    } else if (err.response && err.response.data && err.response.data.msg) {
                        swal("Message!", err.response.data.msg, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                });
        } else {
            setErrorMessage('Please select email.');
        }

    }

    const downloadFile = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = Sample;
        link.download = 'sample.xlsx';
        link.click();
    };

    const handleBlur = (event) => {
        switch (event.target.name) {

            case "choosefile":
                if (!event.target.value) {
                    setErrorFileMessage("Please choose a file");
                } else {
                    const file = event.target.files[0];
                    const fileSize = file.size / 1024 / 1024; // Size in MB
                    const fileName = file.name;
                    const allowedExtensions = ['xlsx'];

                    if (fileName.includes(".")) {
                        const extensions = fileName.split(".");
                        const fileExtension = extensions[extensions.length - 1];
                        const allowedMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

                        if (extensions.length > 2 || fileExtension.includes(".")) {
                            setErrorFileMessage("File should not have a double extension");
                        }
                        else if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
                            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                        }
                        else if (!allowedMimeTypes.includes(file.type)) {
                            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                        }
                        else if (fileSize > 2) {
                            setErrorFileMessage("File size should not exceed 2MB");
                        }
                        else {
                            // Verify the file contents using XLSX library
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                try {
                                    const data = new Uint8Array(e.target.result);
                                    const workbook = XLSX.read(data, { type: "array" });
                                    setErrorFileMessage(false);
                                } catch (error) {
                                    setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                                }
                            };
                            reader.readAsArrayBuffer(file);
                        }
                    } else {
                        setErrorFileMessage(false);
                    }
                }
                break;
        }
    };



    const handleImport = (e) => {
        const file = e.target.files[0];
        const fileSizeLimit = 2 * 1024 * 1024; // 2MB
        const expectedFileExtension = '.xlsx';
        const expectedMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        console.log("type", file.type);



        if (file.type !== expectedMimeType || !file.name.toLowerCase().endsWith(expectedFileExtension)) {
            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
            setExcelData(null);
            setFileData(file);
            return;
        }


        const fileName = file.name;
        const hasDoubleExtension = fileName.includes(".", fileName.indexOf(".") + 1);

        // Check for double extension
        if (hasDoubleExtension) {
            setErrorFileMessage("File should not have a double extension");
            setExcelData(null);
            setFileData(file);

            return;
        }

        if (file.size > fileSizeLimit) {
            setErrorFileMessage("File size should not exceed 2MB");
            setExcelData(null);
            setFileData(file);

            return;
        }


        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            try {
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                setFileData({
                    ...fileData,
                    name: file.name,
                    size: file.size,
                    file: file,

                });


                // Extract headers from the first row of the sheet         
                const headers = [];
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                let C;
                const R = range.s.r; /* start in the first row */
                for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
                    const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
                    const hdr = XLSX.utils.format_cell(cell); /* format the cell to get the header */
                    headers.push(hdr);
                }

                // Compare header values with sample values
                const isMatch = headers.every((header, index) => header === SampleHeaderValues[index]);
                if (!isMatch) {
                    setError("Improper file. Try with sample file");
                    setExcelData(null);
                    setErrorFileMessage("Improper file. Try with sample file")
                    return;
                }

                // Extract data from the sheet, skipping the first row
                const dataRows = XLSX.utils.sheet_to_json(worksheet, { header: headers, range: 1 });
                setExcelData(dataRows);
                setExcel(true)
                setError(null);
            } catch (error) {
                setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
                setFileData(file);
                setExcelData(null);
            }
        };

        reader.readAsArrayBuffer(file);


    };


    const onValidateHandler = (event) => {
        event.preventDefault();
        if (!fileData || !fileData.name) {

            if (!fileData || !fileData.name) {
                setErrorFileMessage("Please choose a file");
            }
            return; // Stop form submission if validation fails
        }

        const fileName = fileData.name;
        const doubleExtensionRegex = /\.[^.\\/]+?\.[^.\\/]+$/;

        const fileExtension = fileName.split('.').pop();
        if (fileExtension.toLowerCase() !== "xlsx") {
            setErrorFileMessage("Invalid file type. Please choose an XLSX file.");
            return; // Stop form submission if validation fails
        }

        if (doubleExtensionRegex.test(fileName)) {
            setErrorFileMessage("File should not have a double extension");
            return; // Stop form submission if validation fails
        }


        if (fileData.size > 2 * 1024 * 1024) {
            setErrorFileMessage("File size should not exceed 2MB");
            return; // Stop form submission if validation fails
        }




        handleExcelSubmit();
    };




    const handleExcelSubmit = (e) => {
        let fileexcel = fileData.file;
        const excelUploadData = {
            challengeListId: challengeListId
        };
        const myString = JSON.stringify(excelUploadData);
        let formData = new FormData();
        formData.append("file", fileexcel);
        formData.append("excelUpload", myString);

        if (excel === true) {
            // if (isChecked) { // Check if isChecked is true
            Services.BulkUploadEmailsforChallengeList(formData)
                .then(async response => {
                    if (response.status === 200) {
                        await swal("Success", response.data.msg, "success");
                        fileInputRef.current.value = '';
                        setExcelData(false);
                        navigate("/admin-dashboard/ctf/view-ctf-list")
                    } else {
                        await swal("Message", response.data.msg, "info");
                    }
                })
                .catch(err => {
                    swal("Error Message", err.response.data.msg, "error");
                });
            // } else {
            //     setErrorCheckMessage(true); // Set setErrorCheckmessage to true
            // }
        } else {
            swal("Oops!", "Improper file. Try with a sample file", "error");
        }
    };



    useEffect(() => {

        $(".btn-toggle-menu").click(function () {
            $("#wrapper").toggleClass("toggled");
        });

        Services.GetListOfEmails()
            .then(response => {
                const transformedEmailOptions = response.data.map(email => ({
                    value: email,
                    label: email,
                }));
                setEmailOptions(transformedEmailOptions);

            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout();
                }
            });
    }, [])



    useEffect(() => {
        Services.GetChallengeListById(challengeListId).then(response => {
            if (response.data.length > 0) {
                const selectedEmails = response.data[0].emailList;
                const initialSelectedOptions = selectedEmails.map(email => ({
                    value: email,
                    label: email,
                }));

                setSelectedOptions(initialSelectedOptions);


            }
        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
                UserUtil.logout();
            } else if (err.response && err.response.status === 400) {
                let errorMessages = Object.values(err.response.data.errors).join('\n');
                swal("Message!", errorMessages, "warning");
            }
            // else {
            //     swal("Message!", "Server error occurred", "warning");
            // }
        });

    }, [])


    useEffect(() => {
        // if (selectedContent === 'bulkUpload') {
        Services.IVPGetEventList()
            .then(response => {
                setEvents(response.data.data);

            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;

                    swal("Message!", errorMessage, "warning");


                    UserUtil.logout();
                }
            });

        // }
    }, []);


    const handleLinkUsers = () => {

        const users = attended.map(user => ({
            email: user.email,
            username: `${user.first_name} ${user.last_name}`, // Assuming firstName is the username field
            ivpUser_id: user.user_id

        }));

        const data = {
            event_unicode: selectedEvent,
            challengeListId: challengeListId,
            users
        }

        Services.LinkUsersToEvent(data)

            .then(async response => {
                if (response.data.status === true) {
                    // if (response.data.existingUsersCount !== null && response.data.newUsersCount !== null) {
                        //const message = `Existing Users Count: ${response.data.existingUsersCount}\nNew Users Count: ${response.data.newUsersCount}`;
                          
                        await swal("Success", response.data.msg, "success");
                        setAttended([]);
                        setSelectedEvent('');
                        setListData(false);
                        // setTimeout(() => window.location.reload(true), 1000);
                    // } else {
                    //     await swal("Success", response.data.msg, "success");
                    // }
                    setTimeout(() => window.location.reload(true), 1000);
                } else {
                    await swal("Message", response.data.msg, "info");
                }

            }).catch(err => {
                if (err.response && err.response.status === 401) {
                    // window.location.href = URLs.CAKES;
                    // const errorMessage = err.response.data.errors.UnAuthorized;

                    // swal("Message!", errorMessage, "warning");
                    // Services.Logout()
                    //     .then(response => {
                    //         if (response.status === 200) {
                    //             sessionStorage.removeItem("token");
                    //             sessionStorage.removeItem("LoggedIn");
                    //             navigate("/");
                    //         }
                    //     })
                    //     .catch(err => {
                    //         // Handle error if needed
                    //     });

                    UserUtil.logout();
                }
                else if (err.response && err.response.data && err.response.data.errors) {
                    let errorMessages = Object.values(err.response.data.errors).join('\n');
                    swal("Message!", errorMessages, "warning");
                } else if (err.response && err.response.data && err.response.data.msg) {
                    swal("Message!", err.response.data.msg, "warning");
                } else {
                    swal("Message!", "Server error occurred", "warning");
                }
            });

    }

    return (
        <div>
            <section className="breadcrumbs">
                <div className="container mt-3">
                    <ol>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-dashboard/ctf">
                                Admin  Dashboard
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-dashboard/ctf/view-ctf-list">
                                CTF List
                            </Link>

                        </li>

                        {/* {!isEditMode ? */}
                        <li>Assign Users to CTF</li>
                        {/* : */}
                        {/* <li>Edit Quiz</li> */}
                        {/* } */}

                    </ol>
                    {/* {!isEditMode ? */}
                    <h2>Assign Users to CTF</h2>
                    {/* : <h2 >Edit Quiz</h2> */}
                    {/* } */}

                </div>
            </section>
            <div className="container-fluid Quiz-admin">
                <div id="wrapper" className="wrapper-content">
                    <div id="sidebar-wrapper">
                        <ul className="sidebar-nav">
                            <li className={selectedContent === 'selectEmails' ? 'active pt-5 ' : 'pt-5'}>
                                <a href="#" className='m-3' onClick={() => setSelectedContent('selectEmails')}><i class="bi bi-envelope-at-fill"></i>Select Emails</a>
                            </li>
                            <li className={selectedContent === 'bulkUpload' ? 'active pt-2' : 'pt-2'}>
                                <a href="#" className='m-3' onClick={() => setSelectedContent('bulkUpload')}><i class="fas fa-cloud-upload-alt"></i>Bulk Upload of Emails</a>
                            </li>
                        </ul>
                    </div>

                    <div id="page-content-wrapper">
                        <nav className="navbar navbar-default">
                            <div className="container-fluid">
                                <div className="navbar-header">
                                    <button
                                        className="btn-menu btn btn-secondary btn-toggle-menu"
                                        type="button"
                                    >
                                        <i className="fa fa-bars" />
                                    </button>
                                </div>
                            </div>
                        </nav>
                        <div className="col-lg-12">
                            {selectedContent === 'selectEmails' ? (
                                <div className='container-fluid'>
                                    <h4 className="py-3 fw-bold">Select Emails</h4>
                                    <div className="card">
                                        <div className='card-leftside-body'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <h3>Select User Emails</h3>
                                                    <div className="mb-3">
                                                        <Multiselect
                                                            options={emailOptions}
                                                            selectedValues={selectedOptions}
                                                            onSelect={onSelect}
                                                            onRemove={onRemove}
                                                            displayValue="label"
                                                            showCheckbox={true}
                                                            searchable={true}
                                                            hidePlaceholder
                                                            showArrow={true}
                                                            onBlur={() => {
                                                                // Check if there are no selected options
                                                                if (selectedOptions.length > 0) {
                                                                    setIsFormValid(true);
                                                                    setErrorMessage('');

                                                                } else {
                                                                    setIsFormValid(false);
                                                                    setErrorMessage('Please select at least one email.');
                                                                }
                                                            }}
                                                            className={errorMessage ? 'error-border' : ''}
                                                        />
                                                        {errorMessage && <p className="error-message text-danger">{errorMessage}</p>}
                                                    </div>
                                                </div>
                                                <div className='text-end mt-4'>
                                                    <button type="submit" className="btn btn-lg btn-success" onClick={onSubmitEmailsHandler}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                  
                                    </div>
                                    <div className='text-center mt-3'>---------------------OR------------------------</div>
                                        <h4 className="py-3 fw-bold">Link Users to Event</h4>
                                        <div className="card">
                                            <div className='card-leftside-body'>
                                                <div className="col-md-6 form-group my-3 custom-select-quiz">
                                                    <h3>Select Event</h3>
                                                    <select className="form-control" id="eventSelect" value={selectedEvent}
                                                        onChange={handleSelectChange}>
                                                        <option>Select Event</option>
                                                        {events.map(event => (
                                                            <option key={event.event_unicode} value={event.event_unicode}>
                                                                {event.event_name}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </div>

                                                <div className="col-md-12 table-responsive mt-4 ">
                                                    {isListData && (

                                                        <DataTable
                                                            columns={columnsLinkUsers}
                                                            data={attended}
                                                            striped
                                                            highlightOnHover
                                                            pagination
                                                            paginationPerPage={10}
                                                            noHeader
                                                            responsive
                                                            customStyles={customStyles}

                                                        />
                                                    )}

                                                </div>

                                                {isListData && (
                                                    <div className='text-end mt-4'>
                                                        <button className='btn btn-success fw-bold' onClick={handleLinkUsers}>Link users to event</button>
                                                    </div>)}

                                            </div>
                                        </div>
                                </div>
                            ) : (
                                <div className='container-fluid'>
                                    <h4 className="py-3 fw-bold">Bulk Upload of Emails</h4>
                                    <div className="card">
                                        <div className='card-leftside-body'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <h3>Choose File</h3>
                                                    <div className="mb-3">
                                                        <input className={errorFileMessage ? "form-control is-invalid" : "form-control"} type="file" name="choosefile" ref={fileInputRef} id="formFile" onChange={handleImport} onBlur={handleBlur} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                                        <div className="invalid-feedback">
                                                            {errorFileMessage && <span className='text-danger'>{errorFileMessage}</span>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <h3>Sample File</h3>
                                                    <div className="mb-3">
                                                        <button className="btn-theme-download" onClick={downloadFile}><i className="fas fa-download p-1"></i>Download Sample Excel</button>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 table-responsive mt-4 ">
                                                    {excelData && (

                                                        <DataTable
                                                            columns={[
                                                                {
                                                                    name: "Sr No.",
                                                                    selector: (row, index) => index + 1,
                                                                    sortable: false,
                                                                    center: true,
                                                                    maxWidth: '200px',
                                                                    wrap: true,
                                                                    compact: true,
                                                                    grow: false,
                                                                },
                                                                ...SampleHeaderValues.map(header => ({
                                                                    name: header,
                                                                    selector: row => {

                                                                        return row[header];
                                                                    },
                                                                    sortable: true,
                                                                    center: true,
                                                                })),
                                                            ]}
                                                            data={excelData}
                                                            customStyles={customStyles}
                                                            responsive
                                                            pagination
                                                            paginationPerPage={10}
                                                        />

                                                    )}

                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                        <strong>{error}</strong>
                                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='text-end mt-4'>
                                                    <button type="submit" className="btn btn-lg btn-success" onClick={onValidateHandler}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AssignUsertoChallengeList