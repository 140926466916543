import Carousel from 'react-bootstrap/Carousel';
import Quiz from "../../src/Images/homeImage4.jpg"
import Quiz1 from "../../src/Images/homeImage5.jpg"
import Quizz from "../../src/Images/bgQuizz1.jpg"
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import mainImage from '../assets/img/banner-1.jpg'
import featuresImage from '../assets/img/features.png'
import quizImage from '../assets/img/values-1.png'
import { Link, useNavigate } from 'react-router-dom';
import { NavDropdown } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import Services from '../../src/Service/Service';
import moment from 'moment';
import Slider from "react-slick";
import challengeImage from '../assets/img/home/challenge1.png'
import ctfImage from '../assets/img/home/Ctf.PNG'
import challengeImage1 from '../assets/img/home/Capture-2.PNG'
import adminGraph1 from '../assets/img/home/Graph/graphh-2.png'
import adminGraph2 from '../assets/img/home/Graph/admin-graph2.png'
import userGraph1 from '../assets/img/home/Graph/user-graph1.png'
import userGraph2 from '../assets/img/home/Graph/user-graph2.png'
import axios from "axios";
import UserUtil from '../UserUtil/UserUtil';
import 'aos/dist/aos.css';
import AOS from 'aos';
// import '../assets/Style.css'

const Home = () => {
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = (quizid) => {
    //     setShow(true)
    // };
    let navigate = useNavigate();
    const LoggedIn = UserUtil.getSessionStorage("IsLoggedIn");
    let roles = "";

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

   
    if (LoggedIn === "true") {
        const token = UserUtil.getSessionStorage("_txyz");
        roles = UserUtil.getRolesFromToken(token);

    }
    const [listQuizzes, setlistQuizzes] = useState([{
        "quizTitle": "",
        "quizId": ""
    }])

  


    useEffect(() => {

        UserUtil.removeSessionStorage("refreshKey");
        UserUtil.setSessionStorage("flag", 0);
        // Services.GetQuizzesByStartDate()
        //     .then(async response => {

        //         setlistQuizzes(response.data);
        //     }).catch(err => {

        //     })
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,

        });
    }, [])

    // useEffect(() => {
    //     window.history.forward();

    // }, []);


    return (
        <div>
            <>

                {/* ======= Hero Section ======= */}
                {/* <section id="hero" className="hero d-flex align-items-center"> */}
                {/* <div className="container"> */}


                {/* <div className='container-fluid'> */}
                    <div className='row'>
                        <img src={mainImage} className="img-fluid main-image" alt="Main-image" />
                    </div>
                {/* </div> */}
                {/* </div> */}

                {/* </section> */}
                <>
                    {/* ======= Details Section ======= */}
                    <section id="details" className="details">
                        <header className="section-header">
                            <p>Our Features</p>
                        </header>
                        <div className="container">
                            <div className="row content">
                                <div className="col-md-6 mt-5" >
                                    <Carousel indicators={true} controls={false} data-aos="fade-right" data-aos-duration={1000}>
                                        <Carousel.Item>
                                            <img src={challengeImage} alt="Image 1" className="img-fluid" width={500} height={400} />
                                            <Carousel.Caption className="carousel-caption-bottom">
                                                <p>A simple challenge</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <img src={challengeImage1} alt="Image 2" className="img-fluid" width={500} height={400} />
                                            <Carousel.Caption className="carousel-caption-bottom">
                                                <p>A challenge with multiple hints</p>
                                            </Carousel.Caption>

                                        </Carousel.Item>
                                        
                                    </Carousel>
                                </div>
                                <div className="col-md-6 pt-4" data-aos="zoom-in" data-aos-duration={1200}>
                                    <h3>
                                        Challenge
                                    </h3>
                                    <p className="fst-italic" >
                                        A challenge is a task or problem designed to test participants' skills in cybersecurity,
                                        reverse engineering,etc where users aim to uncover hidden
                                        flags or solutions to earn points
                                    </p>
                                    {/* <span>Features</span> */}
                                    <ul>
                                        <li>
                                            <i className="bi bi-check-circle-fill" /> Enable the creation of challenges with multiple flags, diversifying the ways participants can solve tasks.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" /> Offer multiple hints for challenges, aiding participants in progressing through difficulties.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" /> Allow inclusion of various file types for challenges, enhancing the complexity and creativity of tasks
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />  Allow to limit the number of attempts allowed per participant, maintaining challenge integrity and strategy.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" /> Allows to provide URL to guide participants towards finding flags, enriching the challenge experience.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" /> Control challenge visibility for different participant groups
                                        </li>
                                    </ul>
                                    {/* <p>
                                        Voluptas nisi in quia excepturi nihil voluptas nam et ut. Expedita
                                        omnis eum consequatur non. Sed in asperiores aut repellendus. Error
                                        quisquam ab maiores. Quibusdam sit in officia
                                    </p> */}
                                </div>
                            </div>
                            <div className="row content">
                                <div className="col-md-6 order-1 order-md-2 mt-5" data-aos="fade-left" data-aos-duration={1000}>
                                    <img src={ctfImage} className="img-responsive" alt="" width={620} height={300}/>
                                </div>
                                <div className="col-md-6 pt-5 order-2 order-md-1" data-aos="fade-right" data-aos-duration={1200}>
                                    <h3>CTF</h3>
                                    <p className="fst-italic">

                                        A CTF (Capture The Flag) platform allows users to craft personalized challenges and assign them to participants
                                    </p>
                                    {/* <span>Features</span> */}
                                    <ul>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />Set specific dates for the CTF's availability.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />Enable or disable visibility of the CTF for participants.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />Option to publish or hide the leaderboard
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />Capability to select multiple challenges to the CTF.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />Assign the created CTF to specific users.
                                        </li>
                                    </ul>
                                    
                                </div>
                            </div>
                            <div className="row content">
                                <div className="col-md-6 mt-5" >
                                <Carousel indicators={true} controls={false} data-aos="fade-left" data-aos-duration={1000}>
                                        <Carousel.Item>
                                            <img src={adminGraph1} alt="Image 1" className="img-fluid" width={500} height={400} />
                                            <Carousel.Caption className="carousel-caption-bottom">
                                                <p>The performance of a user in a specific CTF can be viewed within the admin dashboard</p>
                                            </Carousel.Caption>
                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <img src={adminGraph2} alt="Image 2" className="img-fluid" width={500} height={400} />
                                            <Carousel.Caption className="carousel-caption-bottom">
                                                <p>The user's performance statistics across all attempted challenges are visible within the admin dashboard</p>
                                            </Carousel.Caption>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={userGraph1} alt="Image 2" className="img-fluid" width={500} height={400} />
                                            <Carousel.Caption className="carousel-caption-bottom">
                                                <p>The user's performance across all attempted Capture The Flag (CTF) challenges is visible on the user dashboard</p>
                                            </Carousel.Caption>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={userGraph2} alt="Image 2" className="img-fluid" width={500} height={400} />
                                            <Carousel.Caption className="carousel-caption-bottom">
                                                <p>The user performance statistics within a specific CTF are displayed on the user dashboard</p>
                                            </Carousel.Caption>

                                        </Carousel.Item>
                                        {/* Add more Carousel.Items if needed */}
                                    </Carousel>
                                </div>
                                <div className="col-md-6 pt-5" data-aos="zoom-in" data-aos-duration={1200}>
                                    <h3>
                                        Leaderboard And Graph
                                    </h3>
                                    <p>
                                        Users and admin both have access to a graphical representation of user responses, allowing them to visually analyze and compare performance.
                                    </p>
                                    <ul>
                                        <li>
                                            <i className="bi bi-check-circle-fill" />
                                            Users can track their progress visually through interactive graphs, while also comparing their
                                            performance against others on a dynamic leaderboard.
                                        </li>
                                        <li>
                                            <i className="bi bi-check-circle-fill" /> The admin can view participants' performance in a Capture The Flag (CTF) competition through a visual graph,
                                            offering insights into trends and achievements.
                                        </li>

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </section>
                    {/* End Details Section */}
                </>





                {/* End Hero */}
                {/* <main id="main">

                   
                    <section id="values" className="values section-bg">
                        <div className="container" data-aos="fade-up">
                            <header className="section-header">
                                <p>Live Quizzes</p>                             
                            </header>
                            {listQuizzes.length > 0 ? (

                                <div className="row">
                                    {
                                        listQuizzes.length !== 0 && listQuizzes.map((item, index) => {
                                            return <div className="col-lg-4 mb-3">
                                                <div className="box">
                                                  
                                                    {item.thumbImage && item.thumbImage.substring(item.thumbImage.lastIndexOf('/') + 1) !== "NA" ? (
                                                                
                                                                    <img
                                                                        src={item.thumbImage}
                                                                        alt="Quiz-image"
                                                                        className="img-fluid p-0"                                                                        
                                                                    />
                                                                
                                                            ):(<img src={quizImage} className="img-fluid p-0" alt="Quiz-image" />)}


                                                    <h3 className='text-capitalize fs-5 pt-4'> {item.quizTitle} Quiz</h3>
                                                    

                                                    <div className="quiz-info d-flex fw-bold text-black text-center my-2">
                                                        <div className="questions border-end w-50">
                                                            <p className="w-100 mb-0">{item.quizSize}</p>
                                                            <small className="text-secondary">Questions</small>
                                                        </div>
                                                        <div className="timer w-50">
                                                            <p className="mb-0">{item.totalDuration} &nbsp;<small className="text-secondary">min</small></p>
                                                            <small className="text-secondary">Duration</small>
                                                        </div>
                                                    </div>
                                                    <div className="quiz-info d-flex fw-bold text-black text-center my-2 p-2">
                                                        <div className="questions border-end w-50">
                                                            {item.startDate !== 'NA' ? (
                                                                <p className="w-100 mb-0 fs-6 text-success">{item.startDate}</p>
                                                            ) : (
                                                                <p className="w-100 mb-0 fs-6 text-success">-</p>
                                                            )}
                                                            <small className="text-secondary">Start Date</small>
                                                        </div>
                                                        <div className="timer w-50">
                                                        {item.endDate !== 'NA' ? (
                                                                <p className="w-100 mb-0 fs-6 text-danger">{item.endDate}</p>
                                                            ) : (
                                                                <p className="w-100 mb-0 fs-6 text-danger">-</p>
                                                            )}
                                                            <small className="text-secondary">End Date</small>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>
                                                        <button type="button" className="btn-buy" onClick={() => onStartQuiz(item.quizId)} >Start Quiz</button>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="alert alert-info text-center" role="alert">
                                    <h5> No live quizzes available at the moment.</h5>
                                </div>

                            )}
                        </div>
                    </section>
                </main> */}
            </>

        </div>

    )
}

export default Home;