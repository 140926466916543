import { useEffect } from 'react';
import '../assets/Style.css';
const ErrorPage = () => {

    useEffect(() => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("LoggedIn");        
    }, [])

    return (
        <div>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>
                            4<span>0</span>3
                        </h1>
                    </div>
                    <p>
                    You tried to access a page you did not have prior authorization for.
                    </p>
                    <a href="/" className='pt-2'>home page</a>
                </div>
            </div>

        </div>
    )
}

export default ErrorPage;