import Services from '../../src/Service/Service';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"
import Highcharts from 'highcharts';
import Nav from 'react-bootstrap/Nav';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import { CSVLink } from 'react-csv';

import { useState, useEffect } from 'react';
import UserUtil from '../UserUtil/UserUtil';

const AdminLeaderboard = () => {
    let navigate = useNavigate();
    const [challengeListData, setChallengeListData] = useState(null);
    const [chartPerformanceOptions, setChartPerformanceOptions] = useState({});
    const [activeTab, setActiveTab] = useState('ctfGraph');
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [chartOptions, setChartOptions] = useState({});
    const [selectedCtf, setSelectedCtf] = useState('');
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCtfName, setSelectedCtfName] = useState("");
    const [graphOptions, setGraphOptions] = useState({});
    const [graphPerformanceOptions, setGraphPerformanceOptions] = useState({});

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };


    const customStyles = {
        headCells: {
            style: {
                fontSize: '14px',

                //paddingLeft: '0 8px',
                //marginLeft: '10px',
                fontWeight: 'bold',
            },
        },
        cells: {
            style: {
                fontSize: '15px',
                wrap: 'false',

            },
        },
        headRow: {
            style: {
                backgroundColor: '#ced0f0',
                color: 'black',
                borderRadius: '5px'
            },
        },
    };


    const columns = [
        {
            name: 'Name',
            selector: (row) => row.username,
            center: true
        },
        {
            name: 'Score',
            selector: (row) => row.score,
            center: true,

        },
        {
            name: 'Time Stamp',
            selector: (row) => row.Timestamp,
            maxWidth: '1200px',
            wrap: true,
            center: true,
            compact: true,
            grow: false,

        },
    ];



    const column = [
        {
            name: 'Challenge Name',
            selector: (row) => row.challengeName,
            center: true
        },
        {
            name: 'Correct (%)',
            selector: (row) => row.correct,
            center: true,

        },
        {
            name: 'Wrong (%)',
            selector: (row) => row.wrong,
            center: true,

        },

    ];



    useEffect(() => {
        Services.GetAdminChallengePerformanceGraphData().then(response => {
            setGraphPerformanceOptions(response.data);
            const apiData = response.data;
            const formattedTableData = Object.keys(apiData).map(challengeName => ({
                challengeName: challengeName,
                correct: apiData[challengeName].correct_percentage,
                wrong: apiData[challengeName].wrong_percentage,
            }));

            console.log("formateddata", formattedTableData);
            setTableData(formattedTableData);

            const transformedChartData = Object.keys(apiData).map(challengeName => ({
                name: challengeName,
                data: [
                    apiData[challengeName].correct_percentage,
                    apiData[challengeName].wrong_percentage,

                ]
            }));

            const categories = transformedChartData.map(item => item.name);

            const options = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: categories, // Using challenge names as categories
                    crosshair: true,
                    accessibility: {
                        description: 'Challenges'
                    },
                    title: {
                        text: 'Challenges'
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Percentage'
                    }
                },
                tooltip: {
                    shared: true
                },
                series: [{
                    name: 'Correct',
                    data: transformedChartData.map(item => item.data[0]), // Correct count
                    color: Highcharts.getOptions().colors[0]
                }, {
                    name: 'Wrong',
                    data: transformedChartData.map(item => item.data[1]),
                    color: Highcharts.getOptions().colors[1]
                },],
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0

                    }
                },
                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                'downloadPNG',
                                'downloadJPEG',
                                'downloadPDF',
                                'downloadSVG',
                                'downloadCSV'
                            ]
                        }
                    }
                }
            };

            setChartPerformanceOptions(options);


        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
             UserUtil.logout();
            }
            else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

    }, [])




    useEffect(() => {
        Services.GetChallengeList().then(response => {
            setChallengeListData(response.data);
            console.log("data", response.data);
            if (response.data && response.data.length > 0) {
                const firstCTF = response.data[0];
                setSelectedCtf(firstCTF.challengeListId);
                fetchGraphData(firstCTF.challengeListId, firstCTF.challengeListName); // Fetch graph data for the first CTFfirst CTF
                fetchLeaderBoardData(firstCTF.challengeListId);
            }

        }).catch(err => {
            if (err.response && err.response.status === 401) {
                const errorMessage = err.response.data.errors.UnAuthorized;

                swal("Message!", errorMessage, "warning");
              UserUtil.logout();
            }
            else {
                swal("Message!", "Server error occurred", "warning");
            }
        });

    }, [])

    if (typeof Highcharts === 'object') {
        HighchartsExporting(Highcharts);
    }

    const fetchLeaderBoardData = (challengeListId) => {
        Services.GetLeaderBoard(challengeListId)
            .then(response => {
                setLeaderboardData(response.data);

            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;
                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout()
                }
            });
    };







    const fetchGraphData = (challengeListId, challengeListName) => {
        setSelectedCtfName(challengeListName)
        console.log("Challenge List Name:", challengeListName);
        Services.GetAdminGraphData(challengeListId)
            .then(response => {
                setGraphOptions(response.data);
                const apiData = response.data; // Assuming the API response is an array of objects
                const transformedChartData = Object.values(apiData).map(item => ({
                    name: item.username,
                    data: [item.score]
                }));


                const options = {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: challengeListName
                    },
                    xAxis: {
                        categories: transformedChartData.map(item => item.name), // Assuming transformedData has the required format
                        crosshair: true,
                        accessibility: {
                            description: 'Users'
                        },
                        title: {
                            text: 'Users'
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Scores'
                        }
                    },
                    // tooltip: {
                    //     valueSuffix: ' (1000 MT)'
                    // },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Score', // Series name
                        data: transformedChartData.map(item => item.data[0]) // Assuming data is in the format: { name: 'Username', data: [score] }
                    }],
                    exporting: {
                        enabled: true,
                        buttons: {
                            contextButton: {
                                menuItems: [
                                    'downloadPNG',
                                    'downloadJPEG',
                                    'downloadPDF',
                                    'downloadSVG',
                                    'downloadCSV'
                                ]
                            }
                        }
                    }
                };


                setChartOptions(options);

            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    const errorMessage = err.response.data.errors.UnAuthorized;
                    swal("Message!", errorMessage, "warning");
                    UserUtil.logout()
                }
            });
    };



    const renderTabContent = () => {

        if (activeTab === 'ctfGraph') {
            return <div className="container custom-tab-content mt-3">
                <div className="col-md-4 custom-select-quiz my-4">
                    <h3>Choose CTF</h3>
                    <select
                        className="form-select"
                        data-title="selectQuiz"
                        name="selectQuiz"
                        onChange={(e) => {
                            const selectedId = e.target.value;
                            const selectedName = challengeListData.find(challenge => challenge.challengeListId === selectedId)?.challengeListName;
                            // console.log("ctf Name",selectedName);
                            setSelectedCtf(selectedId);
                            setSelectedCtfName(selectedName)
                            fetchGraphData(selectedId, selectedName);
                            fetchLeaderBoardData(selectedId)
                        }}
                        value={selectedCtf}

                    >
                        {/* <option value="">Choose CTF</option> */}
                        {challengeListData && challengeListData.length > 0 ? (
                            challengeListData.map(challenge => (
                                <option key={challenge.challengeListId} value={challenge.challengeListId}>
                                    {challenge.challengeListName}
                                </option>
                            ))
                        ) : (
                            ""
                        )}
                    </select>
                </div>


                {!graphOptions.msg ? (
                    <div className='card p-2 my-3'>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </div>
                ) : (
                    <div className='alert alert-info fw-bold text-center'>
                        {graphOptions.msg}
                    </div>
                )}



                {/* <div className="col-md-12 d-flex justify-content-end">
            <form className="d-flex m-2" role="search">
                <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={handleFilter}
                                               
                />
            </form>
        </div> */}

                <div className="text-end p-2 mt-4">
                    {downloadCSV()}
                </div>


                <div className='mt-4'>
                    {hasLeaderboardData !== null && leaderboardData.status !== null ? (
                        <DataTable
                            columns={columns}
                            data={leaderboardArray.length > 0 ? leaderboardArray : ''}
                            highlightOnHover
                            pointerOnHover
                            responsive
                            customStyles={customStyles}
                            pagination
                            paginationPerPage={10}
                        />
                    ) : (
                        // <div className='alert alert-info fw-bold text-center'>
                        //     {leaderboardData.msg}
                        // </div>
                        ""
                    )}
                </div>
            </div>
        } else if (activeTab === 'performanceGraph') {

            return <div className='container custom-tab-content'>

                    <div className='card p-2 my-3'>
                        <HighchartsReact highcharts={Highcharts} options={chartPerformanceOptions} />
                    </div>
               

                
                    <div className="text-end p-2 mt-4">
                        {downloadPerformanceCSV()}
                    </div>


                <div className='mt-4'>
                    {tableData !== null && tableData.status !== null ? (
                        <DataTable
                            columns={column}
                            data={tableData.length > 0 ? tableData : ''}
                            highlightOnHover
                            pointerOnHover
                            responsive
                            customStyles={customStyles}
                            pagination
                            paginationPerPage={10}
                        />
                    ) : (
                        <div className='alert alert-info fw-bold text-center'>
                            No data available
                        </div>
                    )}
                </div>

            </div>
        }
    }


    // const transformedData = Array.isArray(chartOptions)
    // ? chartOptions.map(item => [{
    //     name: item.username,
    //     data: [item.score]
    // }])
    // : [];




    // const fetchLeaderBoardData = () => {
    //     Services.GetLeaderBoardAdmin()
    //         .then(response => {
    //             setLeaderboardData(response.data);
    //         })
    //         .catch(err => {
    //             if (err.response && err.response.status === 401) {
    //                 const errorMessage = err.response.data.errors.UnAuthorized;
    //                 swal("Message!", errorMessage, "warning");
    //                 Services.Logout()
    //                     .then(response => {
    //                         if (response.status === 200) {
    //                             sessionStorage.removeItem("token");
    //                             sessionStorage.removeItem("LoggedIn");
    //                             navigate("/");
    //                         }
    //                     })
    //                     .catch(err => {
    //                         // Handle error if needed
    //                     });
    //             }
    //         });
    // };


    // useEffect(() => {
    //     fetchLeaderBoardData();

    //     const intervalId = setInterval(() => {
    //         fetchLeaderBoardData();
    //     }, 30000);


    //     return () => clearInterval(intervalId);
    // }, []);


    const leaderboardArray = Object.values(leaderboardData);

    const hasLeaderboardData = leaderboardArray.length > 0;

    // const handleFilter = (e) => {
    //     const keyword = e.target.value.toLowerCase();
    //     const filtered = leaderboardArray.filter((item) =>
    //         item.username.toLowerCase().includes(keyword)
    //     );
    //     setFilteredData(filtered);
    // };

    const downloadCSV = () => {
        if (!leaderboardData.msg) {
            const csvData = leaderboardArray.map((item, index) => ({
                'Sr No': index + 1,
                'Username': item.username,
                'Score': item.score,
                'Timestamp': item.Timestamp
            }));

            return (
                <CSVLink
                    data={csvData}
                    filename={`${selectedCtfName}-statistics.csv`}
                    className="btn-theme-download"
                >
                    <i className="fas fa-download p-1"></i>Download CSV
                </CSVLink>
            );
        } else {
            return null; // Render nothing when there's no data
        }
    };


    const downloadPerformanceCSV = () => {
        if (!graphPerformanceOptions.msg) {
            const csvData = tableData.map((item, index) => ({
                'Sr No': index + 1,
                'Challenge Name': item.challengeName,
                'Correct': item.correct,
                'Wrong': item.wrong,

            }));

            return (
                <CSVLink
                    data={csvData}
                    filename={'Ctf-performance-list.csv'}
                    className="btn-theme-download"
                >
                    <i className="fas fa-download p-1"></i>Download CSV
                </CSVLink>
            );

        } else {
            return null;
        }
    };



    return (
        <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-dashboard/ctf">
                                Admin  Dashboard
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/dashboard/ctf">
                                CTF
                            </Link>
                        </li> */}
                        <li>
                            Leaderboard
                        </li>
                    </ol>
                    <h2>Leaderboard</h2>
                </div>
            </section>

            <div className='container d-flex justify-content-center mt-4'>
                <Nav variant="pills" activeKey={activeTab} onSelect={handleTabChange} className="custom-nav">
                    <Nav.Item>
                        <Nav.Link eventKey="ctfGraph" className="nav-link-custom">CTF Graph</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="performanceGraph" className="nav-link-custom" >Performance Graph</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div className='mt-4'>
                {renderTabContent()}
            </div>
        </div>
    )
}

export default AdminLeaderboard;