import { Link, useParams, useNavigate } from 'react-router-dom';
import Uservideo from "../assets/video/User-Video.mp4";


const UserVideoTutorial = () => {
    return (
        <div>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/user-dashboard/ctf">
                               User Dashboard
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/dashboard/ctf">
                                CTF
                            </Link>
                        </li> */}

                        <li aria-current="page">User Video Tutorial</li>

                    </ol>
                    <h2>User Video Tutorial</h2>
                </div>
            </section>
            <div className='container my-4 text-center'>
            <video width="640" height="360" controls>
                            <source src={Uservideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
            </div>

        </div>
    )
}

export default UserVideoTutorial