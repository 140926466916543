import Navbar from "./Navbar/Navbar";
import Dashboard from "./Dashboard/DashBoard";
import { Routes, Route,useLocation } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer';
import './App.css';
import axios from 'axios';
import DashBoardRouter from "./DashboardRouter/Dashboardrouter";
import Footer from "./Footer/Footer"
import Home from "./Home/Home";
import { useEffect, useState, } from 'react'
import HomeRouter from "./HomeRouter/HomeRouter";
import QuizResponseRouter from "./QuizResponseRouter/QuizResponseRouter";
import Ivp from "./IVP/Ivp";
import ErrorPage from "./ErrorPage/ErrorPage";
import history from "./History/History";
import Services from "./Service/Service";
import { useNavigate,Navigate } from "react-router-dom";
import Spinner from "./Spinner/Spinner"
import { API_BASE_URL, IVP_BASE_URL } from "./config/config";
import UserUtil from "./UserUtil/UserUtil";
import Login from "./Login/Login";
import UnAuthorized from "./Unauthorized/Unauthorized";
import Header from "./Header/Header";
import ProceedToCtf from "./ProccedToCTf/ProceedToCtf";

function App() {
  let navigate = useNavigate();
  const location = useLocation();
  const USERSERVICE_BASE_URL = API_BASE_URL;
  const showHeaderFooter = ['/login', '/'].includes(location.pathname);

  if (process.env.NODE_ENV === 'production') {
    console.error = () => { }; // Override console.error to do nothing in production
  }

  const onIdle = () => {
    // alert('fires after 10 minutes');
    //insert any custom logout logic here
    // sessionStorage.removeItem("token");
  UserUtil.logout();
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000,   //30minutes
  })



  


  axios.interceptors.request.use(function (config) {

    const isGoogleLogin = config.url.includes(USERSERVICE_BASE_URL + '/social/google/login');
    const isIVPLogin = config.url.includes(IVP_BASE_URL + "/tokengen");
    const isIVPUserInfo = config.url.includes(IVP_BASE_URL + "/userinfo");
    const isIVPLogout = config.url.includes(IVP_BASE_URL + "/ivplogout");
    const isIVPRewards = config.url.includes(IVP_BASE_URL + "/totalRewardPoint");
    const isIVPEvents = config.url.includes(IVP_BASE_URL + "/ivp/allEventList/");
    const isIVPEventsLinkingEmails = config.url.includes(IVP_BASE_URL + "/ivp/eventUserList/");
    const isIVPRewardsDetails = config.url.includes(IVP_BASE_URL + "/lastActivityByApp");
    const isIVPEventGetUserDetails = config.url.includes(IVP_BASE_URL + "/ivp/profile/");
    const isIVPEventUpdateUserDetails = config.url.includes(IVP_BASE_URL + "/updateuserbyid");

    if (!isGoogleLogin && !isIVPLogin && !isIVPUserInfo && !isIVPLogout && !isIVPRewards && !isIVPEvents && !isIVPEventsLinkingEmails && !isIVPRewardsDetails && !isIVPEventGetUserDetails && !isIVPEventUpdateUserDetails ) {
      console.log("called");
      config.headers.Authorization = 'Bearer ' + UserUtil.getSessionStorage("_txyz");
    }

    return config;
  }, function (error) {

    return Promise.reject(error);
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading, replace this with your actual loading logic
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Simulating a 2-second loading time

    return () => clearTimeout(timeout);
  }, []); // Run once on component mount

  return (

    <div>
      {isLoading ? (
        <Spinner />
      ) : (


        <div>
          {showHeaderFooter && <Header />}

          <Routes>


            {/* <Route path="/*" element={<HomeRouter />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin-dashboard/*" element={<DashBoardRouter />} />
            <Route path="/user-dashboard/*" element={<QuizResponseRouter />} />
            <Route path="/ivp-login/:url" element={<Ivp />} />
            <Route path="/proceed-to-ctf" element={<ProceedToCtf />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/unauthorized" element={<UnAuthorized />} />
            <Route path="/*" element={<Navigate to="/unauthorized" />} />
            <Route element={<UnAuthorized/>}/>
          </Routes>
          {showHeaderFooter && <Footer />}
          {/* <Footer /> */}
        </div>
      )}
    </div>
  );
}

export default App;
