import { Link, useNavigate } from 'react-router-dom';
import quizIcon from '../assets/img/quiz.png'
import ctfIcon from '../assets/img/ctfd.png'

const UserDashboard = () => {
    let navigate = useNavigate();
    const switchToQuizSection = () => {
        navigate("/quiz/quiz-dashboard")

    };

    const switchToCTFSection = () => {
        navigate("/quiz/ctf-dashboard")
    };

    return (
        <div className='user-dashboard'>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        {/* <li aria-current="page">Dashboard</li> */}

                    </ol>
                    <h2>User Dashboard</h2>
                </div>
            </section>
            <div className="container">
                <>
                    <div className="message mt-4 fw-bold">
                        <p>Welcome, User! This application offers a combination of quiz and CTF features. Please select the option you'd like to access from the choices below.</p>
                    </div>
                    <div className="section_our_solution my-3">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="our_solution_category">
                                    <div className="solution_cards_box">
                                        <div className="solution_card" onClick={switchToQuizSection}>
                                            <div className="hover_color_bubble" />
                                            <div className="so_top_icon">
                                                <img src={quizIcon} width={40} height={50} />
                                            </div>
                                            <div className="solu_title">
                                                <h3 className="text-center">Quiz</h3>
                                            </div>
                                            <div className="solu_description">
                                                <p>
                                                    A quiz is a game or mind sport where users answer questions on one or more topics. Quizzes can be used to assess users knowledge, provide insights into progress, and identify knowledge gaps.
                                                </p>
                                                {/* <button type="button" className="read_more_btn">
                                                            Read More
                                                        </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="our_solution_category">
                                    <div className="solution_cards_box">
                                        <div className="solution_card" onClick={switchToCTFSection}>
                                            <div className="hover_color_bubble" />
                                            <div className="so_top_icon">
                                                <img src={ctfIcon} width={40} height={50} />
                                            </div>
                                            <div className="solu_title">
                                                <h3 className="text-center">CTF</h3>
                                            </div>
                                            <div className="solu_description">
                                                <p>
                                                    Capture the Flag (CTF) competitions are exercises in which participants, either individually or as part of a team, are challenged to find and exploit vulnerabilities in a system to capture a "flag" or piece of information.
                                                </p>
                                                {/* <button type="button" className="read_more_btn">
                                                            Read More
                                                        </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        </div>
    )
}
export default UserDashboard