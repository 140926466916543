import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Editor } from "@tinymce/tinymce-react";
import Button from 'react-bootstrap/Button';
import swal from "sweetalert"
import Services from '../../src/Service/Service';
import UserUtil from '../UserUtil/UserUtil';


const CreateChallenge = () => {
    const [selectedOption, setSelectedOption] = useState("standard");
    const [editorData, setEditorData] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [show1, setShow1] = useState(false);
    const [errors, setErrors] = useState({});
    const [fileList, setFileList] = useState([]);
    const editorRef = useRef(null);
    let navigate = useNavigate();
    let flagIndex = 1;
    const [challengeData, setChallengeData] = useState({
        challengeName: '',
        description: '',
        category: '',
        value: '',
        flags: {},
        state: 'hidden',
        link: '',
        noOfAttempts: ''

    });

    const getEditorValue = (evt) => {
        setEditorData(evt.target.getContent({ format: "html" }));
        //console.log(evt.target.getContent({ format: "text" }));     
    };



    const handleClose1 = () => {
        setShow1(false);
    }
    const handleShow1 = () => {
        const validationPassed = onValidateHandler();

        if (validationPassed) {
            setShow1(true);
        }
    }

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const onhandleFileChange = (event) => {
        const newfileList = Array.from(event.target.files);
        const fileNames = newfileList.map(file => file.name);
        setSelectedFiles(fileNames);
        setFileList(newfileList);
        validateField('files', newfileList);

    }


    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name.startsWith("flag")) {
            let index = 1;
            //const flagIndex = name.substring(4); // Assuming the input name is like "flag1", "flag2", ...
            const updatedFlags = {
                ...challengeData.flags,
                [index]: {
                    ...challengeData.flags[index],
                    flag: value,
                },

            };

            setChallengeData((prevData) => ({
                ...prevData,
                flags: updatedFlags,
            }));
        } else if (name === "isCaseSensitive") {
            const isCaseSensitive = value === "true";
            setChallengeData((prevData) => ({
                ...prevData,
                flags: Object.keys(prevData.flags).reduce((acc, index) => {
                    acc[index] = {
                        ...prevData.flags[index],
                        isCaseSensitive: isCaseSensitive,
                    };
                    return acc;
                }, {}),
            }));
        } else {
            // If the 'name' is 'state', update the 'state' field
            if (name === 'state') {
                setChallengeData((prevData) => ({
                    ...prevData,
                    state: value, // Set the state value to the selected option value
                }));
            } else {
                setChallengeData({ ...challengeData, [name]: value });
            }
        }
    }



    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'challengeName' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }
        if (name === 'category' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        if (name === 'description' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }

        if (name === 'value' && value.trim().length > 0) {
            validateField(name, value, 'blur');
        }
    };


    const validateField = (name, value) => {
        let errorsCopy = { ...errors };
        switch (name) {
            case 'challengeName':
                if (!value.trim()) {
                    errorsCopy.challengeName = 'Challenge Name is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    errorsCopy.challengeName = 'Challenge Name must contain only alphanumeric characters and spaces';
                } else {
                    delete errorsCopy.challengeName;
                }
                break;

            case 'category': // Add validation for category
                if (value.length > 255) {
                    errorsCopy.category = 'Category must not exceed 255 characters';
                } else {
                    delete errorsCopy.category;
                }
                break;

            case 'description':
                if (!value.trim()) {
                    errorsCopy.description = 'Description is required';
                } else {
                    delete errorsCopy.description;
                }

                break;
            case 'value':
                if (!value.trim()) {
                    errorsCopy.value = 'Value is required';
                } else if (!/^\d+$/.test(value)) {
                    errorsCopy.value = 'Value must contain only digits';
                } else {
                    delete errorsCopy.value;
                }
                break;

            case 'files': // Handle file validation
                const invalidFiles = [];

                value.forEach(file => {
                    if (file.size > 5 * 1024 * 1024) {
                        invalidFiles.push(file.name + ' exceeds 5MB');
                    }

                    const fileNameParts = file.name.split('.');
                    if (fileNameParts.length > 2) {
                        invalidFiles.push(file.name + ' has a double extension');
                    }
                });

                if (invalidFiles.length > 0) {
                    errorsCopy.files = invalidFiles.join(', ');
                } else {
                    delete errorsCopy.files;
                }
                break;




        }
        setErrors(errorsCopy);
    };


    const onValidateHandler = () => {
        const { challengeName, value, category } = challengeData;
        const errors = {};

        if (challengeName === "") {
            errors.challengeName = "Challenge Name is required";
        } else if (!/^[A-Za-z0-9\s]+$/.test(challengeName)) {
            errors.challengeName = 'Challenge Name must contain only alphanumeric characters and spaces';
        }


        if (!editorData) {
            errors.description = "Description is required";
        }

        if (value === "") {
            errors.value = "Value is required";
        } else if (!/^\d+$/.test(value)) {
            errors.value = 'Value must contain only digits';
        }

        if (category.length > 255) {
            errors.category = "Category must not exceed 255 characters";
        }


        fileList.forEach(file => {
            if (file.size > 5 * 1024 * 1024) {
                errors[file.name] = `${file.name} exceeds 5MB`;
            }

            const fileNameParts = file.name.split('.');
            if (fileNameParts.length > 2) {
                errors[file.name] = `${file.name} has a double extension`;
            }
        });



        setErrors(errors);

        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;

    }






    const onSubmitChallengeData = () => {

        const data = {
            ...challengeData,
            description: editorData,
            challengeType: "standard",
            hints: {}
        }

        const UpdatedData = JSON.stringify(data);
        let formData = new FormData();
        formData.append("challenge", UpdatedData);
        fileList.forEach(file => {
            formData.append('files', file);
        });
        const validationPassed = onValidateHandler();
        if (validationPassed) {

            Services.CreateChallenge(formData)

                .then(async response => {

                    if (response.status === 200) {
                        await swal("Success", response.data.msg, "success");
                        let challengeId = response.data.id;

                        setChallengeData({
                            challengeName: '',
                            description: '',
                            category: '',
                            value: '',
                            flags: {},
                            state: ''
                        });

                        setEditorData('');
                        setSelectedFiles([]);
                        setFileList([]);
                        setSelectedOption("standard");
                        setShow1(false);
                        navigate(`/admin-dashboard/ctf/update-standard-challenge/${challengeId}`);

                    }
                    else {
                        await swal("Message", response.data.msg, "info")
                    }
                }).catch(err => {
                    if (err.response && err.response.status === 401) {
                        const errorMessage = err.response.data.errors.UnAuthorized;

                        swal("Message!", errorMessage, "warning");
                        UserUtil.logout();
                    }
                    else if (err.response && err.response.data && err.response.data.errors) {
                        let errorMessages = Object.values(err.response.data.errors).join('\n');
                        swal("Message!", errorMessages, "warning");
                    } else if (err.response && err.response.data && err.response.data.msg) {
                        swal("Message!", err.response.data.msg, "warning");
                    } else {
                        swal("Message!", "Server error occurred", "warning");
                    }
                });
        }
    }






    return (
        <div className='Quiz-admin'>
            <section className="breadcrumbs">
                <div className="container mt-3">

                    <ol>
                        <li>
                            <Link to="/">
                                <i class="bi bi-house-door-fill"></i>
                                Home
                            </Link>
                        </li>

                        <li>
                            <Link to="/admin-dashboard/ctf">
                                Admin  Dashboard
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/dashboard/ctf">
                                CTF
                            </Link>
                        </li> */}

                        <li aria-current="page">Create Challenge</li>

                    </ol>
                    <h2>Create Challenge</h2>
                </div>
            </section>
            <div className="container-fluid wrapper">
                <div className="row">
                    <div className="col-md-3 offset-md-1">
                        <h4 className="text-center pb-3">Challenge Types</h4>
                        <div id="create-chals-select">
                            <label className="w-100">
                                <div className="card rounded-0">
                                    <div className="card-body">
                                        <span className="card-title">
                                            <div className='form-check'>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    value="standard"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onChange={handleRadioChange}
                                                    checked={selectedOption === 'standard'}
                                                />
                                                <h3 className="form-check-label">Standard</h3>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </label>
                            {/* <label className="w-100">
                                <div className="card rounded-0">
                                    <div className="card-body">
                                        <span className="card-title">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    value="code"
                                                    onChange={handleRadioChange}
                                                />
                                                <h3 className="form-check-label">Code</h3>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </label>
                            <label className="w-100">
                                <div className="card rounded-0">
                                    <div className="card-body">
                                        <span className="card-title">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    value="dynamic"
                                                    onChange={handleRadioChange}
                                                />
                                                <h3 className="form-check-label">Dynamic</h3>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </label>
                            <label className="w-100">
                                <div className="card rounded-0">
                                    <div className="card-body">
                                        <span className="card-title">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    value="manual_verification"
                                                    onChange={handleRadioChange}
                                                />
                                                <h3 className="form-check-label">Manual_Verification</h3>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </label>
                            <label className="w-100">
                                <div className="card rounded-0">
                                    <div className="card-body">
                                        <span className="card-title">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    value="multiple_choice"
                                                    onChange={handleRadioChange}
                                                />
                                                <h3 className="form-check-label">Multiple_Choice</h3>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </label> */}
                        </div>
                    </div>
                    <div className='col-md-7'>
                        {selectedOption === 'standard' &&
                            <div>
                                <div className='mb-2'>
                                    <h3 className='required'>Name</h3>
                                    <input
                                        type="text"
                                        className={errors.challengeName ? "form-control is-invalid" : "form-control"}
                                        id="challengeName"
                                        name='challengeName'
                                        value={challengeData.challengeName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <div id="challengeName" className="form-text">
                                        The name of your challenge
                                    </div>
                                    <div className="invalid-feedback">
                                        {errors.challengeName && <span>{errors.challengeName}</span>}
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <h3>Category</h3>
                                    <input
                                        type="text"
                                        className={errors.category ? "form-control is-invalid" : "form-control"}
                                        value={challengeData.category}
                                        id="category"
                                        name='category'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <div id="category" className="form-text">
                                        The category of your challenge
                                    </div>
                                    <div className="invalid-feedback">
                                        {errors.category && <span>{errors.category}</span>}
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <h3 className='required'>Description</h3>
                                    <Editor
                                        //apiKey="uccq416s6f5b64u1f3jtpdocbt3f7120y7w4zfmtzqxfcvyk"
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        name="description"
                                        onBlur={(evt) => handleBlur({ target: { name: 'description', value: evt.target.getContent({ format: 'text' }) } })}
                                        // initialValue={editorData}
                                        // onChange={getEditorValue}
                                        onEditorChange={(val) => setEditorData(val)}
                                        value={editorData}
                                        init={{
                                            height: 300,
                                            auto_focus: true,
                                            menubar: false,
                                            max_width: 300,
                                            skin: "oxide-dark",
                                            mobile: {
                                                menubar: true,
                                                theme: 'mobile'
                                            },
                                            branding: false,
                                            plugins: [
                                                'codesample', 'media', 'code', 'image'
                                            ],
                                            toolbar:
                                                'undo redo codesample code media link image| casechange blocks | bold italic backcolor | \
                                             alignleft aligncenter alignright alignjustify | \
                                             bullist numlist checklist outdent indent | removeformat |',
                                            elementpath: false,
                                            image_title: true,
                                            automatic_uploads: true,
                                            file_picker_types: 'image',
                                            file_picker_callback: function (cb, value, meta) {
                                                var input = document.createElement('input');
                                                input.setAttribute('type', 'file');
                                                input.setAttribute('accept', 'image/*');

                                                // Handle file selection
                                                input.onchange = function () {
                                                    var file = input.files[0];
                                                    var reader = new FileReader();

                                                    // Read the selected file and return its URL
                                                    reader.onload = function () {
                                                        var url = reader.result;
                                                        cb(url, { title: file.name });
                                                    };

                                                    // Read the file as a data URL
                                                    reader.readAsDataURL(file);
                                                };

                                                // Trigger a click event on the file input element to open the file picker dialog
                                                input.click();
                                            },

                                            // images_upload_url: '/your-image-upload-endpoint', // Replace with your actual image upload URL
                                            // images_upload_handler: function (blobInfo, success, failure) {
                                            //     // Create a FormData object to send the image data to the server
                                            //     const formData = new FormData();
                                            //     formData.append('file', blobInfo.blob());
                                            //     Services.UploadImage(formData)
                                            //         .then(response => {
                                            //             if (response.data.status === true) {
                                            //                 // Handle success here, for example, show a success message
                                            //                 swal("Success", response.data.msg, "success");
                                            //                 success(response.data.url); // Provide the uploaded image URL to the editor
                                            //             } else {
                                            //                 // Handle non-successful response, show an info message
                                            //                 swal("Message!", response.data.msg, "info");
                                            //                 failure('Image upload failed');
                                            //             }
                                            //         })
                                            //         .catch(err => {
                                            //             // Handle errors, for example, show a warning message
                                            //             swal("Message!", err.response.data.errors, "warning");
                                            //             failure('Image upload failed');
                                            //         });
                                            // },


                                            // forced_root_block: '',
                                            // force_br_newlines: true,
                                            relative_urls: false,
                                            remove_script_host: false,
                                            convert_urls: true,
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            forced_root_block: '', // Disable forcing of <p> tags on Enter
                                            force_br_newlines: true, // Enable <br> on Enter
                                            newline_behavior: 'linebreak', // Insert <br> tags for line breaks

                                        }}
                                    />

                                    <div className="form-text">
                                        Use this to give a brief introduction to your challenge.
                                    </div>
                                    {errors.description && <div className='fs-6 text-danger'>{errors.description}</div>}
                                </div>
                                <div className='mb-2'>
                                    <h3 className='required'>Value</h3>
                                    <input
                                        type="number"
                                        className={errors.value ? "form-control is-invalid" : "form-control"}
                                        id="value"
                                        name='value'
                                        value={challengeData.value}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <div id="value" className="form-text">
                                        This is how many points are rewarded for solving this challenge.
                                    </div>
                                    <div className="invalid-feedback">
                                        {errors.value && <span>{errors.value}</span>}
                                    </div>
                                </div>
                                <div className='mb-2 text-end'>
                                    <button type="submit" className="btn btn-lg btn-success" onClick={handleShow1}>
                                        Create
                                    </button>
                                </div>
                            </div>}
                        {selectedOption === 'code' && <p>Display content for code option here.</p>}
                        {selectedOption === 'dynamic' && <p>Display content for dynamic option here.</p>}
                        {selectedOption === 'manual_verification' && <p>Display content for manual verification option here.</p>}
                        {selectedOption === 'multiple_choice' && <p>Display content for multiple choice option here.</p>}
                    </div>

                </div>
            </div>
            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4>Options</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='Quiz-admin'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='mb-2'>
                                <h3>Flag</h3>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="Flag"
                                    name='flags'
                                    aria-describedby="emailHelp"
                                    onChange={handleChange}
                                />
                                <div className="form-text">
                                    Static flag for your challenge
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mb-2 custom-select-quiz'>
                                <h3>Case Sensitive</h3>
                                <select className="form-select" name="isCaseSensitive" aria-label="Default select example" onChange={handleChange}>
                                    <option value="0" selected="">Choose Case Sensitive</option>
                                    <option value="true">Case Sensitive</option>
                                    <option value="false">Case Insensitive</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <h3>Files</h3>
                        <input className={errors.files ? "form-control is-invalid" : "form-control"} name="files" type="file" id="formFile" onChange={onhandleFileChange} multiple />
                        <div className="form-text">
                            Files distributed along with your challenge
                        </div>
                        <div className="form-text">
                            Attach multiple files using Control+Click or Cmd+Click
                        </div>
                        <div className="invalid-feedback">
                            {errors.files && <span>{errors.files}</span>}
                        </div>
                        <ul>
                            {selectedFiles.map((fileName, index) => (
                                <li className='fw-bold' key={index}>{fileName}</li>
                            ))}
                        </ul>
                    </div>

                    <div className='mb-2 custom-select-quiz'>
                        <h3>State</h3>
                        <select className="form-select" name="state" aria-label="Default select example" onChange={handleChange}>
                            {/* <option selected="">Choose State</option> */}
                            <option value="visible">Visible</option>
                            <option selected value="hidden">Hidden</option>
                        </select>
                        <div className="form-text">
                            Should the challenge be visible to users
                        </div>
                    </div>
                    <div className='mb-2 text-end'>
                        <button type="submit" className="btn btn-success" onClick={onSubmitChallengeData}>
                            Finish
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose1}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateChallenge;